function morphTitle () {
    
    let a = KUTE.to('.a1', { path: '.a2' }, { duration: 3000,  yoyo: true, repeat: true});
    let aMask = KUTE.to('.mask-a1', { path: '.mask-a2' }, { duration: 3000,  yoyo: true, repeat: true });
    a.start();
    aMask.start();

    let f = KUTE.to('.f1', { path: '.f2' }, { duration: 3000, yoyo: true, repeat: true});
    f.start();

    let r = KUTE.to('.r1', { path: '.r2' }, { duration: 3000, yoyo: true, repeat: true});
    let rMask = KUTE.to('#mask-r1', { path: '#mask-r2' }, { duration: 3000, yoyo: true, repeat: true});

    r.start();
    rMask.start();

    let i = KUTE.to('.i1', { path: '.i2' }, { duration: 3000, yoyo: true, repeat: true});
    i.start();


    let c = KUTE.to('.c1', { path: '.c2' }, { duration: 3000, yoyo: true, repeat: true});
    c.start();

    let a_2 = KUTE.to('.a1-2', { path: '.a2-2' }, { duration: 3000, yoyo: true, repeat: true});
    let aMask_2 = KUTE.to('.mask-a1-2', { path: '.mask-a2-2' }, { duration: 3000, yoyo: true, repeat: true});
    
    a_2.start();
    aMask_2.start();


}
module.exports = morphTitle;