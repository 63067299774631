function animations() {
    const tlCardHand = gsap.timeline({
        scrollTrigger: {
            trigger: ".hand",

            start: "top bottom",
            // endTrigger: ".promo__cards .hand",
            // end: "bottom center"
        },// children inherit these defaults
        duration: 1,
            //delay: 1,
            //ease: "back.out"
        
    });
    if(1101 <= screen.width) {
        tlCardHand.fromTo(".hand img", {y: 0, opacity: 0}, { y: "-80%", opacity: 1});
    }
    if (992 <= screen.width <= 1100) {
        tlCardHand.fromTo(".hand img", {y: 0, opacity: 0}, { y: "-90%", opacity: 1});
    }
    if (769 <= screen.width <= 991) {
        tlCardHand.fromTo(".hand img", {y: 0, opacity: 0}, { y: "-60%", opacity: 1});
    }
    if (600 <= screen.width <= 768) {
        tlCardHand.fromTo(".hand img", {y: 0, opacity: 0}, { y: "-60%", opacity: 1});
    }
    if (501 <= screen.width <= 599) {
        tlCardHand.fromTo(".hand img", {y: 0, opacity: 0}, { y: "-80%", opacity: 1});
    }
    if (400 <= screen.width <= 500) {
        tlCardHand.fromTo(".hand img", {y: 0, opacity: 0}, { y: "-70%", opacity: 1});
    }
    if (350 <= screen.width <= 399) {
        tlCardHand.fromTo(".hand img", {y: 0, opacity: 0}, { y: "-90%", opacity: 1});
    }
    if (screen.width <= 351) {
        tlCardHand.fromTo(".hand img", {y: 0, opacity: 0}, { y: "-100%", opacity: 1});
    }


    const lineMusic = document.querySelector(".stroke-line");

    let lineMusicLength = lineMusic.getTotalLength();
    lineMusic.style.strokeDasharray = lineMusicLength;
    lineMusic.style.strokeDashoffset = lineMusicLength;

    const tlShowLineMusic = gsap.timeline({
        scrollTrigger: {
            trigger: ".promo__cards .music",
            start: "top bottom"
        },
        
    });

    tlShowLineMusic.from(".stroke-line", { opacity: 0, duration: 1})
    .to(".stroke-line", { strokeDashoffset: 0, ease: "power1.out", duration: 1});

    const lineNature = document.querySelector('.nature-line');

    let lineNatureLength = lineNature.getTotalLength();
    lineNature.style.strokeDasharray = lineNatureLength;
    lineNature.style.strokeDashoffset = lineNatureLength;
    const tlShowLineNature = gsap.timeline({
        scrollTrigger: {
            trigger: ".promo__cards .nature",
            start: "top bottom"
        }
    });
    tlShowLineNature.from(".nature-line", { opacity: 0, duration: 1})
    .to(".nature-line", { strokeDashoffset: 0, ease: "power1.out", duration: 15});

    const lineSport = document.querySelector('.sport-line');
    let lineSportLength = lineSport.getTotalLength();
    lineSport.style.strokeDasharray = lineSportLength;
    lineSport.style.strokeDashoffset = lineSportLength;
    const tlShowLineSport = gsap.timeline({
        scrollTrigger: {
            trigger: ".promo__cards .sport",
            start: "top bottom"
        }
    });
    tlShowLineSport.from(".sport-line", { opacity: 0, duration: 1})
    .to(".sport-line", { strokeDashoffset: 0, ease: "power1.out", duration: 1});

    const lineEcology = document.querySelector('.ecology-line');
    let lineEcologyLength = lineEcology.getTotalLength();
    lineEcology.style.strokeDasharray = lineEcologyLength;
    lineEcology.style.strokeDashoffset = lineEcologyLength;
    const tlShowLineEcology = gsap.timeline({
        scrollTrigger: {
            trigger: ".promo__cards .ecology",
            start: "top bottom"
        }
    });
    tlShowLineEcology.from(".ecology-line", { opacity: 0, duration: 1})
    .to(".ecology-line", { strokeDashoffset: 0, ease: "power1.out", duration: 2, stagger: 0.5 }, "<");

    const lineCulture = document.querySelector('.culture-line');
    let lineCultureLength = lineCulture.getTotalLength();
    lineCulture.style.strokeDasharray = lineCultureLength;
    lineCulture.style.strokeDashoffset = lineCultureLength;
    const tlShowLineCulture = gsap.timeline({
        scrollTrigger: {
            trigger: ".promo__cards .culture",
            start: "top bottom"
        }
    });
    tlShowLineCulture.from(".culture-line", { opacity: 0, duration: 1})
    .to(".culture-line", { strokeDashoffset: 0, ease: "power1.out", duration: 12, stagger: 0.5 }, "<");

    const lineHistory = document.querySelector('.history-line');
    let lineHistoryLength = lineEcology.getTotalLength();
    lineHistory.style.strokeDasharray = lineHistoryLength;
    lineHistory.style.strokeDashoffset = lineHistoryLength;
    const tlShowLineHistory = gsap.timeline({
        scrollTrigger: {
            trigger: ".promo__cards .history",
            start: "top bottom"
        }
    });
    tlShowLineHistory.from(".history-line", { opacity: 0, duration: 1})
    .to(".history-line", { strokeDashoffset: 0, ease: "power1.out", duration: 2, stagger: 0.5 }, "<");

    const tlShowCulturalImg = gsap.timeline({
        scrollTrigger: {
            trigger: ".promo__cards .cultural",
            start: "top bottom"
        }
    });
    tlShowCulturalImg.from(".cultural img", { opacity: 0, duration: 1})
    .to(".cultural img", { opacity: 1, duration: 2 }, "<");

    //tlShowCulturalOrnament
    gsap.to('.description__svg-top, .description__svg-bottom', {
        x: '-100%',
        duration: 10,
        repeat: -1,
        ease: "none"
    })

    const tlShowRenaissance = gsap.timeline({
        scrollTrigger: {
            trigger: ".renaissance",
            start: "top bottom"
        },
    });

    tlShowRenaissance.from(".renaissance .sun", { opacity: 0, bottom: '0', duration: 1})
    .to(".renaissance .sun", { opacity: 1, bottom: "30%", duration: 1}, "<");

    tlShowRenaissance.from(".renaissance .city", { opacity: 0})
    .to(".renaissance .city", { opacity: 1});

    const tlShowPeople = gsap.timeline({
        scrollTrigger: {
            trigger: ".people",
            start: "top bottom"
        },
    });

    tlShowPeople.from(".people img", { opacity: 0})
    .to(".people img", { opacity: 1});

    const tlShowEarth = gsap.timeline({
        scrollTrigger: {
            trigger: ".global",
            start: "top bottom"
        },
        defaults: {
            delay: 1,
        }
    });

    tlShowEarth.from(".item-card.global img", { opacity: 0, bottom: '-150px', duration: 1})
    .to(".item-card.global img", { opacity: 1, bottom: 0, duration: 2 }, "<");

    // let lineGreenFooter = document.querySelector(".green-animate");
   
    // let lineLengthFooter = lineGreenFooter.getTotalLength();
    // lineGreenFooter.style.strokeDasharray = lineLengthFooter;
    // lineGreenFooter.style.strokeDashoffset = lineLengthFooter;

    // const tlShowLineFooter = gsap.timeline({
    //     scrollTrigger: {
    //         trigger: ".footer",
    //         start: "top center"
    //     }
    // });
    
    // tlShowLineFooter.from(".green-animate", { opacity: 0, duration: 1 }).to(".green-animate", { opacity:1, strokeDashoffset: 0, ease: "power1.out", duration: 2, stagger: 0.3 });

    // let lineOrangeFooter = document.querySelector(".orange-line path");
    // let lineLengtOrangeFooter = lineOrangeFooter.getTotalLength();
    // lineOrangeFooter.style.strokeDasharray = lineLengtOrangeFooter;
    // lineOrangeFooter.style.strokeDashoffset = lineLengtOrangeFooter;

    // tlShowLineFooter.from(".orange-line path", { opacity: 0, duration: 2 }).to(".orange-line path", { opacity:1, strokeDashoffset: 0, ease: "power1.out", duration: 4, stagger: 0.3 });

    const cards = document.querySelectorAll('.item-card');
    const icons = document.querySelectorAll('.item-icon');

    gsap.utils.toArray('.item-card').forEach(item => {
        gsap.from(item, {
            scrollTrigger: {
                trigger: item,
                start: "center bottom"
            },
            y: '250px',
            opacity: 0,
            duration: .5,
            ease: 'power1.out'
       
        });
    });

    gsap.utils.toArray('.item-icon').forEach(item => {
        gsap.from(item, {
            scrollTrigger: {
                trigger: item,
                start: "center bottom"
            },
            y: '250px',
            opacity: 0,
            duration: 1,
            ease: 'power1.out'
       
        });
    });
    function scaleCards (items) {
        items.forEach(card => {
            //card.style.transition = 'transform .6s ease-in-out';
            card.addEventListener('mouseover', () => {
                
                card.style.transform = `scale(1.02)`;
            });
            card.addEventListener('mouseleave', () => {
                card.style.transform = `scale(1)`;
            })
        })
    }
    //scaleCards (cards);
    //scaleCards (icons);
}
module.exports = animations;
