function parallax() {
    const parallax = document.querySelector('.parallax__body');
    const greenContinent = document.querySelector('.promo__continent-green');
    const yellowContunent = document.querySelector('.promo__continent-yellow');
    const orangeContinent = document.querySelector('.promo__continent-orange');
    const greenLine = document.querySelector('.promo__line-green');
    const yellowLine = document.querySelector('.promo__line-yellow');
    const orangeLine = document.querySelector('.promo__line-orange');
    const parentGreenContinent = greenContinent.parentElement;
    const parentYellowContunent = yellowContunent.parentElement;
    const parentOrangeContinent = orangeContinent.parentElement;
    const parentGreenLine = greenLine.parentElement;
    const parentYellowLine = yellowLine.parentElement;
    const parentOrangeLine = orangeLine.parentElement;
    const forGreenContinent = 40;
    const forYellowContunent = 40;
    const forOrangeContinent = 40;
    const forGreenLine = 70;
    const forYellowLine = 70;
    const forOrangeLine = 70;

    const speed = 0.1;

    let positionX = 0, positionY = 0;
    let coordXprocent = 0, coordYprocent = 0;
    function setMouseParallaxStyle() {
        const distX = coordXprocent - positionX;
        const distY = coordYprocent - positionY;

        positionX = positionX + (distX * speed);
        positionY = positionY + (distY * speed);
        //positionY.toFixed(2);

        parentGreenContinent.style.transform = `translate(${positionX / forGreenContinent}%,${positionY / forGreenContinent}%)`;
        greenContinent.style.transform = `rotateY(${positionX}deg)`;
        parentYellowContunent.style.transform = `translate(${positionX / forYellowContunent}%,${positionY / forYellowContunent}%)`;
        yellowContunent.style.transform = `rotateY(${positionX}deg)`;
        parentOrangeContinent.style.transform = `translate(${positionX / forOrangeContinent}%,${positionY / forOrangeContinent}%)`;
        orangeContinent.style.transform = `rotateY(${positionX}deg)`;
        parentGreenLine.style.transform = `translate(${positionX / forGreenLine}%,${positionY / forGreenLine}%)`;
        greenLine.style.transform = `rotateY(${positionX}deg)`;
        parentYellowLine.style.transform = `translate(${positionX / forYellowLine}%,${positionY / forYellowLine}%)`;
        parentOrangeLine.style.transform = `translate(${positionX / forOrangeLine}%,${positionY / forOrangeLine}%)`;
        orangeLine.style.transform = `rotateY(${positionX}deg)`;
        requestAnimationFrame(setMouseParallaxStyle);
    }
    setMouseParallaxStyle();

    parallax.addEventListener("mousemove", function (e) {
        const parallaxWidth = parallax.offsetWidth;
        const parallaxHeight = parallax.offsetHeight;

        const coordX = e.pageX - parallaxWidth / 2;
        const coordY = e.pageY - parallaxHeight / 2;

        coordXprocent = coordX / parallaxWidth * 100;
        coordYprocent = coordY / parallaxHeight * 100;
    });
    
    
    // parallax.addEventListener("mouseleave", function () {

    //     const coordX = 0;
    //     const coordY = 0;

    //     coordXprocent = coordX / parallaxWidth * 100;
    //     coordYprocent = coordY / parallaxHeight * 100;
    // });

    // let threshholdSets = [];
    // for (let i = 0; i <= 1.0; i += 0.005) {
    //     threshholdSets.pish(i);
    // }

    // const callback = function (entries, observer) {
    //     const scrollTopProcent = window.pageYOffset / parallax.offsetHeight * 100;
    //     setParallaxItemsStyle(scrollTopProcent);
    // };

    // const observer = new IntersectionObserver(callback, {
    //     threshold: threshholdSets
    // });

    // observer.observe(document.querySelector('.container'));

    // function setParallaxItemsStyle(scrollTopProcent) {
    //     table.style.transform = `translate(0%,-${scrollTopProcent / 9}%)`;
    //     chiars.style.transform = `translate(0%,${scrollTopProcent / 6}%)`;
    //     pictures.style.transform = `translate(0%,${scrollTopProcent / 3}%)`;
    // }
}

module.exports = parallax;