function openModals() {
  const modals = document.querySelectorAll(".item-open");
  const modalCards = document.querySelectorAll(".modal");
  const closeBtn = document.querySelectorAll(".modal-close");
  const overlay = document.querySelector(".overlay");
  const main = document.querySelector(".main");
  const links = document.querySelectorAll(".item-link");
  const roundClose = document.querySelectorAll('.card__close-round');


  // let x = 0;
  // let y =  0;
  // function clickPosition () {
  //     let x = 0;
  //     let y =  0;
  //     document.addEventListener('click', event => {
  //         const target = event.target.parentElement.parentElement;
  //         if (target.classList.contains('item-click') || target.classList.contains('item-card') || (target.classList.contains('item-icon')) || (target.classList.contains('promo__cards')) || (target.classList.contains('background-img'))) {
  //             x = event.clientX;
  //             y = event.clientY;
  //             return x, y;
  //         }
  //     })
  // }
  // clickPosition();
  // console.log(x, y);
  links.forEach((link) => {
    link.addEventListener("click", function (event) {
      event.preventDefault();
      openModal(link);
    });
  });

  closeBtn.forEach((close) => {
    close.addEventListener("click", function () {
      closeModal();
    });
  });

  roundClose.forEach((close) => {
    close.addEventListener('click', function () {
      closeModal();
    });
  });

  overlay.addEventListener("click", function () {
    closeModal();
  });

  function openModal(link) {
    const href = link.getAttribute("href");
    const modal = document.querySelector(href);
   
    modal.classList.add("modal-active");
    modal.style.display = "block";
    overlay.style.display = "block";
    main.style.filter = "blur(4px)";
    document.body.style.overflow = "hidden";
    const tlModal = gsap.timeline({
      defaults: {
        duration: 1,
        ease: "power4.out",
      },
    });

    tlModal.fromTo(".modal-active", {autoAlpha: 0, scale: 0.1,}, {autoAlpha: 1, scale: 1})
  }

  function closeModal(event) {
    const modalOpen = document.querySelector(".modal-active");
    overlay.style.display = "none";
    main.style.filter = "none";
    document.body.style.overflow = "auto";
    document.body.style.pointerEvents = "none"

    const tlModal = gsap.timeline({
        defaults: {
          duration: 1,
          ease: "power4.out",
        },
        onComplete: () => {
            modalOpen.style.display = "none";
            document.body.style.pointerEvents = "auto"
        }
    });
    
    tlModal.to(".modal-active", {autoAlpha: 0, scale: 0.1})
    modalOpen.classList.remove("modal-active");
  }

}

module.exports = openModals;
