function gsapAnimation() {
  gsap.registerPlugin(MotionPathPlugin);

  let line = document.querySelectorAll(".stroke-animate");

  line.forEach((item) => {
    let lineLength = item.getTotalLength();
    item.style.strokeDasharray = lineLength;
    item.style.strokeDashoffset = lineLength;
    //   gsap.to(item, { strokeDashoffset: 0, duration: 8, ease: "power1.out"})
  });

  const tlShowLine = gsap.timeline({
    delay: 0.5,
  });

  tlShowLine.from(".stroke-animate", { opacity: 0, duration: 2 }).to(".stroke-animate", { strokeDashoffset: 0, ease: "power1.out", duration: 4, stagger: 0.3 }, "<");

  gsap.utils.toArray(".promo__big-title svg").forEach((letter, i) => {
    gsap.from(letter, {
      y: "random([-100, 100, 200])",
      // y: "50%",
      opacity: 0,
      duration: 2,
      ease: "power1.out",
    });
  });


  gsap.from(".promo__title", { scale: 5, opacity: 0, duration: 3, ease: "power1.out" });

  let wordMotion = gsap.to(".word", {
    duration: 15,
    ease: "none",
    immediateRender: true,
    stagger: {
        each: 1,
        repeat: -1,
        onStart: () => {
        }
    },
    motionPath: {
        path: ".path",
        align: ".path",
        start: 1,
        end: 0,
        alignOrigin : [ 0.5 , 0.5 ], 
    }
  });

  let wordMotion2 = gsap.to(".word-2", {
    duration: 15,
    // delay: 7.6,
    ease: "none",
    immediateRender: true,
    stagger: {
        each: 1,
        repeat: -1,
    },
    motionPath: {
        path: ".path",
        align: ".path",
        start: 1,
        end: 0,
        alignOrigin : [ 0.5 , 0.5 ], 
    }
  })

  wordMotion.progress(0.5);
  // wordMotion2.progress(0.5);


//   gsap.utils.toArray(".word").forEach((word, i) => {
//     gsap.to(word[i], {
//         paused:true,
//         immediateRender: true,
//         duration: 15,
//         ease: "none",
//         repeat: -1,
//         onUpdate: () => {
//             test[i++].play()
//         },
//         motionPath: {
//             path: ".path",
//             start: 1,
//             end: 0,
//             alignOrigin : [ 0.5 , 0.5 ], 
//         }
//       })
//     });

}


module.exports = gsapAnimation;
