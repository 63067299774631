"use strict";

import openModals from './modules/openModals';
import gsapAnimation from './modules/gsap-animation';
import animations from './modules/animations';
import cards from './modules/cards';
import parallax from './modules/parallax';
import modals from './modules/modals';
import morphTitle from './modules/morph-title';
import feedback from './modules/feedback';

window.addEventListener("DOMContentLoaded", () => {
    feedback();
    openModals();
    gsapAnimation();
    animations();
    cards();
    parallax();
    modals();

    if (window.innerWidth > 768) {
        morphTitle();
    }

    SmoothScroll({
        // Время скролла 400 = 0.4 секунды
        animationTime: 700,
        // Размер шага в пикселях
        stepSize: 100,
    
        // Дополнительные настройки:
    
        // Ускорение
        accelerationDelta: 20,
        // Максимальное ускорение
        accelerationMax: 1,
    
        // Поддержка клавиатуры
        keyboardSupport: true,
        // Шаг скролла стрелками на клавиатуре в пикселях
        arrowScroll: 50,
    
        // Pulse (less tweakable)
        // ratio of "tail" to "acceleration"
        pulseAlgorithm: true,
        pulseScale: 4,
        pulseNormalize: 1,
    
        // Поддержка тачпада
        touchpadSupport: true,
      });
    
    const soundOn = document.querySelector('.promo__sound-on');
    const soundOff = document.querySelector('.promo__sound-off');
    soundOn.addEventListener('click', ()=> {
        soundOn.style.display = 'none';
        soundOff.style.display = 'block';
    });

    soundOff.addEventListener('click', ()=> {
        soundOn.style.display = 'block';
        soundOff.style.display = 'none';
    });

    const scrollTop = document.querySelector('.scroll-top');
    scrollTop.addEventListener('click', () => {
        document.body.style.transition = `all 0.6s easy`;
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0;
    });
    function slider() {
        let slideIndex = 1;
        let offset = 0;
        const slides = document.querySelectorAll('.offer__slide'),
            slider = document.querySelector('.offer__slider'),
            slidesWrapper = document.querySelector('.offer__slider-wrapper'),
            slidesField = document.querySelector('.offer__slider-inner'),
            dots = document.querySelectorAll('.dot'),
            width = window.getComputedStyle(slidesWrapper).width;
            slidesField.style.width = 100 * slides.length + '%';
            slidesField.style.display = 'flex';
            slidesField.style.transition = '0.6s';
            slidesWrapper.style.overflow = 'hidden';
        slides.forEach(slide => {
            slide.style.width = width + 'px';
        });
        slider.style.position = 'relative';

        function stylingDots() {
            dots.forEach(dot => dot.style.backgroundColor = '#CEA102');
            dots[slideIndex - 1].style.backgroundColor = '#161817';
        }
        stylingDots();
        function deleteNoDigits(str) {
            //return +str.replace(/\D/g, '');
            return Math.round(+str.replace(/px/g, ''))
        }
        dots.forEach(dot => {
            dot.addEventListener('click', (e) => {
                const slideTo = e.target.getAttribute('data-slide-to');
                slideIndex = slideTo;
                offset = deleteNoDigits(width) * (slideTo - 1);
                slidesField.style.transform = `translateX(-${offset}px)`;
                stylingDots();
            });
        });

        //showSlides(slideIndex);
        let timer = 0;//
        makeTimer(); //Создаем интервал 
        function makeTimer(){
           clearInterval(timer) //Очистим интервал, это позволит прервать его работу и отменить перелистывание
            timer = setInterval(function(){
                slideIndex++;
                if (slideIndex == 4) {
                    slideIndex = 1;
                }
                offset = deleteNoDigits(width) * (slideIndex - 1);
                slidesField.style.transform = `translateX(-${offset}px)`;
                stylingDots();
                //showSlides(slideIndex);
            },5000);
        }
    }
    slider();
});