function modals () {
    function rightsAnimate () {
        document.addEventListener('click', () => {
            if(event.target.parentNode.classList.contains('hand') || event.target.parentNode.parentNode.classList.contains('hand')) {
                const tlShowHands = gsap.timeline({
                    delay: 1,
                    stagger: .5
                });
                tlShowHands.fromTo('.rights__img-left', {opacity:0, x: -100, y: 100}, {opacity: 1, x: 0, y: 0},'>')
                .fromTo('.rights__img-right', {opacity:0, x: 100, y: -100}, {opacity: 1, x: 0, y: 0},'>')
                .fromTo('.rights__title', {opacity:0, x: -100,}, {opacity: 1, x: 0},'>')
                .fromTo('.rights__text p', {opacity:0, x: 100,}, {opacity: 1, x: 0},'>')
            }
        });
    }
    function prosperousAnimate () {
        document.addEventListener('click', () => {
            if(event.target.parentNode.classList.contains('flower') || event.target.parentNode.parentNode.classList.contains('flower') || event.target.parentNode.parentNode.parentNode.classList.contains('flower')) {
                const tlShowHands = gsap.timeline({
                    delay: 1,
                    stagger: .5
                });
                if (window.screen.width > 1280) {
                    tlShowHands.fromTo('.prosperous__img', {opacity: 0}, {opacity: 1},'>')
                    .fromTo('.prosperous__circle:last-child', {opacity:0, x: 100}, {opacity: 1, x: 0},'>')
                    .fromTo('.prosperous__circle:nth-child(2)', {opacity:0, x: 300}, {opacity: 1, x: 250},'>')
                    .fromTo('.prosperous__circle:first-child', {opacity:0, x: 300}, {opacity: 1, x: 0},'>')
                    .fromTo('.prosperous__title', {opacity: 0}, {opacity: 1},'>')
                    .fromTo('.prosperous__text p:first-child', {opacity: 0}, {opacity: 1},'>')
                    .fromTo('.prosperous__text p:last-child', {opacity: 0}, {opacity: 1},'>');
                }
                
                if (window.screen.width <= 1280) {
                    tlShowHands.fromTo('.prosperous__img', {opacity: 0}, {opacity: 1},'>')
                    .fromTo('.prosperous__circle:last-child', {opacity:0, x: 100}, {opacity: 1, x: -55},'>')
                    .fromTo('.prosperous__circle:nth-child(2)', {opacity:0, x: 300}, {opacity: 1, x: 115},'>')
                    .fromTo('.prosperous__circle:first-child', {opacity:0, x: 300}, {opacity: 1, x: -100},'>')
                    .fromTo('.prosperous__title', {opacity: 0}, {opacity: 1},'>')
                    .fromTo('.prosperous__text p:first-child', {opacity: 0}, {opacity: 1},'>')
                    .fromTo('.prosperous__text p:last-child', {opacity: 0}, {opacity: 1},'>');
                }
            }
        });
    }
    function renaissanceAnimate () {
        document.addEventListener('click', () => {
            if(event.target.parentNode.classList.contains('renaissance') || event.target.parentNode.parentNode.classList.contains('renaissance') || event.target.parentNode.parentNode.parentNode.classList.contains('renaissance')) {
                const tlShowHands = gsap.timeline({
                    delay: 1,
                    stagger: .5
                });
                tlShowHands.fromTo('.renaissance__circle', {opacity: 0, y: 100}, {opacity: 1, y: 50}, '50%')
                .fromTo('.renaissance__img-box .renaissance__img', {opacity:0,}, {opacity: 1,},'>')
                .fromTo('.renaissance__title', {opacity:0, x: -100}, {opacity: 1, x: 0},'>')
                .fromTo('.renaissance__text', {opacity:0, x: 100}, {opacity: 1, x: 0},'>');
            }
        });
    }
    function economicAnimate () {
        document.addEventListener('click', () => {
            if(event.target.parentNode.classList.contains('invest') || event.target.parentNode.parentNode.classList.contains('invest') || event.target.parentNode.parentNode.parentNode.classList.contains('invest')) {
                const tlShowHands = gsap.timeline({
                    delay: 1,
                    stagger: .5
                });
                tlShowHands.fromTo('.economic__title', {opacity: 0, x: -100}, {opacity: 1, x: 50}, '>')
                .fromTo('.economic__text p:first-child', {opacity:0, x: 100}, {opacity: 1, x: 0},'>')
                .fromTo('.economic__text p:last-child', {opacity:0, x: 100}, {opacity: 1, x: 0},'>')
                .fromTo('.economic__img', {opacity:0, y: 100}, {opacity: 1, y: 0},'>');
            }
        });
    }
    function secureAnimate () {
        document.addEventListener('click', () => {
            if(event.target.parentNode.classList.contains('peaceful') || event.target.parentNode.parentNode.classList.contains('peaceful') || event.target.parentNode.parentNode.parentNode.classList.contains('peaceful')) {
                const tlShowHands = gsap.timeline({
                    delay: 1,
                    stagger: .5
                });
                tlShowHands.fromTo('.secure__title', {opacity: 0, x: 100}, {opacity: 1, x: 50}, '>')
                .fromTo('.secure__text p:first-child', {opacity:0, y: 100}, {opacity: 1, y: 0},'>')
                .fromTo('.secure__text p:last-child', {opacity:0, y: 100}, {opacity: 1, y: 0},'>');
            }
        });
    }
    function peopleAnimate () {
        document.addEventListener('click', () => {
            if(event.target.parentNode.classList.contains('people') || event.target.parentNode.parentNode.classList.contains('people') || event.target.parentNode.parentNode.parentNode.classList.contains('people')) {
                
                const tlShowHands = gsap.timeline({
                    delay: 1,
                    stagger: .5
                });
                tlShowHands.fromTo('.people__title', {opacity: 0, x: -100}, {opacity: 1, x: 0}, '>')
                .fromTo('.people__text p:first-child', {opacity:0, x: 100}, {opacity: 1, x: 0},'>')
                .fromTo('.people__text p:last-child', {opacity:0, x: 100}, {opacity: 1, x: 0},'>')
                .fromTo('.people__img-box', {opacity:0, y: 100}, {opacity: 1, y: 0},'>');
            }
        });
    }
    function identityAnimate () {
        document.addEventListener('click', () => {
            if(event.target.parentNode.classList.contains('cultural') || event.target.parentNode.parentNode.classList.contains('cultural') || event.target.parentNode.parentNode.parentNode.classList.contains('cultural')) {
                
                const tlShowHands = gsap.timeline({
                    delay: 1,
                    stagger: .5
                });
                tlShowHands.fromTo('.identity__title', {opacity: 0, x: -100}, {opacity: 1, x: 0}, '>')
                .fromTo('.identity__text p:first-child', {opacity:0, x: 100}, {opacity: 1, x: 0},'>')
                .fromTo('.identity__text p:last-child', {opacity:0, x: 100}, {opacity: 1, x: 0},'>')
                .fromTo('.identity__img-box', {opacity:0, y: 100}, {opacity: 1, y: 0},'>');
            }
        });
    }
    function socialAnimate () {
        document.addEventListener('click', () => {
            if(event.target.parentNode.classList.contains('social') || event.target.parentNode.parentNode.classList.contains('social') || event.target.parentNode.parentNode.parentNode.classList.contains('social')) {
                const bottomBox = document.querySelector('.social__bottom-box');
                bottomBox.classList.add('yellow');
                const tlShowHands = gsap.timeline({
                    delay: 1,
                    stagger: .5
                });
                tlShowHands.fromTo('.social__img', {filter: 'blur(5px)'}, {filter: 'blur(0px)'}, '>')
                .fromTo('.social__title', {opacity:0, x: -100}, {opacity: 1, x: 0},'>')
                .fromTo('.social__text p:first-child', {opacity:0, y: 100}, {opacity: 1, y: 0},'>')
                .fromTo('.social__text p:last-child', {opacity:0, y: 100}, {opacity: 1, y: 0},'>');
            }
        });
    }

    function globalAnimate () {
        document.addEventListener('click', () => {
            if(event.target.parentNode.classList.contains('global') || event.target.parentNode.parentNode.classList.contains('global') || event.target.parentNode.parentNode.parentNode.classList.contains('global')) {
                const tlShowHands = gsap.timeline({
                    delay: 1,
                });
                tlShowHands.fromTo('img.global__img', {opacity: 0, x: 150}, {opacity: 1, x: 0}, )
                .fromTo('.global__title', {opacity:0, x: 100}, {opacity: 1, x: 0},)
                .fromTo('.global__text p:first-child', {opacity:0, y: 100}, {opacity: 1, y: 0},)
                .fromTo('.global__text p:last-child', {opacity:0, y: 100}, {opacity: 1, y: 0},);
            }
        });
    }

    rightsAnimate();
    prosperousAnimate();
    renaissanceAnimate();
    economicAnimate();
    secureAnimate();
    peopleAnimate();
    identityAnimate();
    socialAnimate();
    globalAnimate();
}
module.exports = modals;