function cards() {
    const videos = document.querySelectorAll('.card video');
    const videoBtn = document.querySelectorAll('.video__btn');
    const videoLines = document.querySelectorAll('.video__line svg path');
    const overlay = document.querySelector('.overlay');
    const tlShowLine = gsap.timeline({});
        
    videoBtn.forEach((item, i, arr) => {
        item.style.opacity = '1';
        item.style.transition = 'all .6s ease';
        
        item.addEventListener('click', () => {
            videos[i].play();
            item.style.opacity = '0';
            item.style.pointerEvents = 'none';

            let lineVideoLength = videoLines[i].getTotalLength();
            videoLines[i].style.strokeDasharray = lineVideoLength;
            videoLines[i].style.strokeDashoffset = 0;
            tlShowLine.from(videoLines[i], { opacity: 1, duration: .5 }).to((videoLines[i]), { opacity: 0, strokeDashoffset: lineVideoLength, ease: "power1.out", duration: 1, stagger: 0.3 });
        });

    });
    
    videos.forEach((item, i, arr) => {
        item.addEventListener('click', () => {

            item.pause();
            videoBtn[i].style.pointerEvents = 'auto';
            videoBtn[i].style.opacity = '1';
           
            tlShowLine.from(videoLines[i], { opacity: 0, duration: .5 }).to((videoLines[i]), { opacity: 1, strokeDashoffset: 0, ease: "power1.out", duration: 1, stagger: 0.3 });
        });
        overlay.addEventListener('click', () => {
           
            item.pause();
            videoBtn[i].style.pointerEvents = 'auto';
            videoBtn[i].style.opacity = '1';
            
            tlShowLine.from(videoLines[i], { opacity: 0, duration: .5 }).to((videoLines[i]), { opacity: 1, strokeDashoffset: 0, ease: "power1.out", duration: 1, stagger: 0.3 });
    
        });
    });

    function musicAnimate () {
        
        document.addEventListener('click', () => {
            if(event.target.parentNode.classList.contains('music') || event.target.parentNode.parentNode.classList.contains('music')) {
                let line = document.querySelectorAll(".card__line svg path");
                const tlShowLineMusic = gsap.timeline({
                    delay: 0.5
                });
                line.forEach((item) => {
                    let lineLength = item.getTotalLength();
                    item.style.strokeDasharray = lineLength;
                    item.style.strokeDashoffset = lineLength;
                });
                tlShowLineMusic.from(".card__line svg path", { opacity: 0, duration: 1})
                    .to(".card__line svg path", { strokeDashoffset: 0, ease: "power1.out", duration: 2}, "<");

                const tlShowDanser = gsap.timeline({
                    delay: 1,
                    stagger: .5
                });
                tlShowDanser.fromTo('.card-music .first .subtitle', {opacity:0, x: 100}, {opacity: 1, x: 0}, )
                .fromTo('.card-music .first .text', {opacity:0, x: 100}, {opacity: 1, x: 0}, '50%')
                .fromTo('.card-music .first .image', {opacity:0, y: 100}, {opacity: 1, y: 0}, '100%')
                .fromTo('.card-music .card__article--reverse .subtitle', {opacity:0, x: -100}, {opacity: 1, x: 0}, '<')
                .fromTo('.card-music .card__article--reverse .text', {opacity:0, x: -100}, {opacity: 1, x: 0}, '<')
                .fromTo('.card-music .card__article--reverse .image', {opacity:0, y: 100}, {opacity: 1, y: 0});
                
            }
        });
    }
    function natureAnimate () {
        
        document.addEventListener('click', () => {
            if(event.target.parentNode.classList.contains('nature') || event.target.parentNode.parentNode.classList.contains('nature')) {
                let line = document.querySelectorAll(".card__line svg path");
                const tlShowLineMusic = gsap.timeline({
                    delay: 0.5
                });
                line.forEach((item) => {
                    let lineLength = item.getTotalLength();
                    item.style.strokeDasharray = lineLength;
                    item.style.strokeDashoffset = lineLength;
                });
                tlShowLineMusic.from(".card__line svg path", { opacity: 0, duration: 1})
                    .to(".card__line svg path", { strokeDashoffset: 0, ease: "power1.out", duration: 2}, "<");

                const tlShowDanser = gsap.timeline({
                    delay: 1,
                    stagger: .5
                });
                tlShowDanser.fromTo('.card-nature .first .subtitle', {opacity:0, x: -100}, {opacity: 1, x: 0}, )
                .fromTo('.card-nature .first .image.first-img', {opacity:0, x: 100}, {opacity: 1, x: 0}, '<')
                .fromTo('.card-nature .first .image.sec-img', {opacity:0, x: 100}, {opacity: 1, x: 0}, '<')
                .fromTo('.card-nature .card__article--reverse .text', {opacity:0, x: 100}, {opacity: 1, x: 0}, '<')
                .fromTo('.card-nature .card__article--reverse .image', {opacity:0, y: 100}, {opacity: 1, y: 0});
                
            }
        });
    }
    function sportAnimate () {
        
        document.addEventListener('click', () => {
            if(event.target.parentNode.classList.contains('sport') || event.target.parentNode.parentNode.classList.contains('sport')) {
                let line = document.querySelectorAll(".card__line svg path");
                let lines = document.querySelectorAll('.card__circles .lines svg path');
                const tlShowLineMusic = gsap.timeline({
                    delay: 0.5
                });
                line.forEach((item) => {
                    let lineLength = item.getTotalLength();
                    item.style.strokeDasharray = lineLength;
                    item.style.strokeDashoffset = lineLength;
                });
                lines.forEach((item) => {
                    let linesLength = item.getTotalLength();
                    item.style.strokeDasharray = linesLength;
                    item.style.strokeDashoffset = linesLength;
                });
                tlShowLineMusic.from(".card__line svg path", { opacity: 0, duration: 1})
                    .to(".card__line svg path", { strokeDashoffset: 0, ease: "power1.out", duration: 2}, "<")
                    .from(".card__circles .lines svg path", { opacity: 0, duration: 1})
                    .to(".card__circles .lines svg path", { strokeDashoffset: 0, ease: "power1.out", duration: 2}, "<");

                const tlShowDanser = gsap.timeline({
                    delay: 1,
                    stagger: .5
                });
                tlShowDanser.fromTo('.card-sport .first .subtitle', {opacity:0, x: -100}, {opacity: 1, x: 0}, '<')
                .fromTo('.card-sport .first .text', {opacity:0, x: 100}, {opacity: 1, x: 0}, '<')
                .fromTo('.card-nature .second .subtitle', {opacity:0, x: -100}, {opacity: 1, x: 0}, '<')
                .fromTo('.card-nature .second .text', {opacity:0, x: 100}, {opacity: 1, x: 0}, '<');
                
            }
        });
    }
    function ecologyAnimate () {
        
        document.addEventListener('click', () => {
            if(event.target.parentNode.classList.contains('ecology') || event.target.parentNode.parentNode.classList.contains('ecology')) {
                let line = document.querySelectorAll(".card__line svg path");
                const tlShowLineMusic = gsap.timeline({
                    delay: 0.5
                });
                line.forEach((item) => {
                    let lineLength = item.getTotalLength();
                    item.style.strokeDasharray = lineLength;
                    item.style.strokeDashoffset = lineLength;
                });
                tlShowLineMusic.from(".card__line svg path", { opacity: 0, duration: 1})
                    .to(".card__line svg path", { strokeDashoffset: 0, ease: "power1.out", duration: 2}, "<");

                const tlShowDanser = gsap.timeline({
                    delay: 1,
                    stagger: .5
                });
                tlShowDanser.fromTo('.card-ecology .image.first', {opacity:0, x: -100}, {opacity: 1, x: 0},)
                .fromTo('.card-ecology .image.boy', {opacity:0, x: 100}, {opacity: 1, x: 0},)
                .fromTo('.card-ecology .subtitle', {opacity:0, y: 100 }, {opacity: 1, y: 0}, )
                .fromTo('.card-ecology .text', {opacity:0, y: 100}, {opacity: 1, y: 0}, '100%');
                
            }
        });
    }
    function cultureAnimate () {
        
        document.addEventListener('click', () => {
            if(event.target.parentNode.classList.contains('culture') || event.target.parentNode.parentNode.classList.contains('culture')) {
                let line = document.querySelectorAll(".card__line svg path");
                const tlShowLineMusic = gsap.timeline({
                    delay: 0.5
                });
                line.forEach((item) => {
                    let lineLength = item.getTotalLength();
                    item.style.strokeDasharray = lineLength;
                    item.style.strokeDashoffset = lineLength;
                });
                tlShowLineMusic.from(".card__line svg path", { opacity: 0, duration: 1})
                    .to(".card__line svg path", { strokeDashoffset: 0, ease: "power1.out", duration: 2}, "<");

                const tlShowDanser = gsap.timeline({
                    delay: 1,
                    stagger: .5
                });
                tlShowDanser.fromTo('.card-culture .first .subtitle', {opacity:0, x: -100}, {opacity: 1, x: 0}, )
                .fromTo('.card-culture .first .image.first-img', {opacity:0, x: 100}, {opacity: 1, x: 0}, '<')
                .fromTo('.card-culture .first .image.sec-img', {opacity:0, x: 100}, {opacity: 1, x: 0}, '<')
                .fromTo('.card-culture .card__article--reverse .text', {opacity:0, x: 100}, {opacity: 1, x: 0}, '<')
                .fromTo('.card-culture .card__article--reverse .image', {opacity:0, y: 100}, {opacity: 1, y: 0});
                
            }
        });
    }
    function historyAnimate () {
        
        document.addEventListener('click', () => {
            if(event.target.parentNode.classList.contains('history') || event.target.parentNode.parentNode.classList.contains('history')) {
                let line = document.querySelectorAll(".card__line svg path");
                const tlShowLineMusic = gsap.timeline({
                    delay: 0.5
                });
                line.forEach((item) => {
                    let lineLength = item.getTotalLength();
                    item.style.strokeDasharray = lineLength;
                    item.style.strokeDashoffset = lineLength;
                });
                tlShowLineMusic.from(".card__line svg path", { opacity: 0, duration: 1})
                    .to(".card__line svg path", { strokeDashoffset: 0, ease: "power1.out", duration: 2}, "<");

                const tlShowDanser = gsap.timeline({
                    delay: 1,
                    stagger: .5
                });
                tlShowDanser.fromTo('.card-history .first .subtitle', {opacity:0, x: 100}, {opacity: 1, x: 0}, )
                .fromTo('.card-history .first .text', {opacity:0, x: 100}, {opacity: 1, x: 0}, '50%')
                .fromTo('.card-history .first .image', {opacity:0, y: 100}, {opacity: 1, y: 0}, '100%')
                .fromTo('.card-history .card__article--reverse .subtitle', {opacity:0, x: -100}, {opacity: 1, x: 0}, '<')
                .fromTo('.card-history .card__article--reverse .text', {opacity:0, x: -100}, {opacity: 1, x: 0}, '<')
                .fromTo('.card-history .card__article--reverse .image', {opacity:0, y: 100}, {opacity: 1, y: 0});
                
            }
        });
    }
    musicAnimate();
    natureAnimate();
    sportAnimate();
    ecologyAnimate();
    cultureAnimate();
    historyAnimate();
}

module.exports = cards;