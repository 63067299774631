function feedback() {
    const openForm = document.querySelector(".footer__btn");
    const form = document.querySelector(".feedback");
    const closeBtn = document.querySelector(".feedback__close");
    const response = document.querySelector(".feedback__response");
    const responseTitle = document.querySelector(".response-title");
    const responseText = document.querySelector(".response-text");
    const feedbackButtons = document.querySelector('.feedback__buttons');
    const homeButon = document.querySelector('.feedback__btn--response');
    const sendAgain = document.querySelector('.feedback__again');

    window.addEventListener("scroll", () => {
        let top = window.pageYOffset;
        openForm.addEventListener("click", () => {
            form.style.display = "flex";
            form.style.opacity = 1;
            form.style.zIndex = 14;
            form.style.top = `${top}px`;
            document.body.style.overflow = "hidden";
            feedbackButtons.style.opacity = '0';
            const line = document.querySelectorAll('.feedback svg path');

            const tlShowLine = gsap.timeline({
                delay: 0.5
            });
            line.forEach((item) => {
                let lineLength = item.getTotalLength();
                item.style.strokeDasharray = lineLength;
                item.style.strokeDashoffset = lineLength;
            });
            tlShowLine.from(".feedback svg path", { opacity: 0, duration: 1})
                .to(".feedback svg path", {opacity: 1, strokeDashoffset: 0, ease: "power1.out", duration: 3}, "<");
        });
    });

    closeBtn.addEventListener("click", () => {
        form.style.opacity = 0;
        form.style.zIndex = -1;
        document.body.style.overflow = "auto";
        form.reset();
    });

    homeButon.addEventListener("click", () => {
        form.style.opacity = 0;
        form.style.zIndex = -1;
        document.body.style.overflow = "auto";
        form.reset();
    });
    function sendForm() {
        form.addEventListener("submit", async function (event) {
            event.preventDefault();
            feedbackButtons.style.opacity = '1';
            let response = await fetch("/mailer/mail.php", {
                method: "POST",
                body: new FormData(form),
            });
    
            if (response.ok) {
                console.log("send");
                responseTitle.innerHTML = "Thank you!"
                responseText.innerHTML = "Your Message has been sent succesfully";
            } else {
                console.log("error: " + response.status);
                responseText.innerHTML = "Message could not be sent";
            }
            let tlHideForm = gsap.timeline({
                defaults: {
                    duration: 1.5,
                    onComplete: () => {
    
                    }
                }
            }); 
            tlHideForm
                .to(".feedback-animation", { autoAlpha: 0, x: -600, stagger: 0.1 })
                .fromTo(".feedback__response", { autoAlpha: 0, x: 400}, {autoAlpha: 1, x: 0}, "<50%");
            form.reset();
        });
    }
   
    sendForm();

    sendAgain.addEventListener('click', () => {
        let tlShowForm = gsap.timeline({
            defaults: {
                duration: 1.5,
                onComplete: () => {

                }
            }
        });
       tlShowForm.to(".feedback__response", { x: 400, autoAlpha: 0, })
                .to(".feedback-animation", { autoAlpha: 1, x: 0, stagger: 0.1 });
        
    });
   // sendForm();
}
module.exports = feedback;
